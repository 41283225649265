import type { ReactNode } from 'react';

import type { IconOption } from '@common/atoms/Icon';

export type Column = {
    title?: string;
    abbreviatedTitle?: string;
    description?: string;
    icon?: IconOption;
    class?: string;
    alignment?: Alignment;
    hideOnMobile?: boolean;
    headerColSpan?: number;
};

export type Row = {
    [key: string]: number[] | ReactNode;
};

export type Columns = {
    [key: string]: Column;
};

export enum Alignment {
    Left,
    Right,
    Regular,
    Centered,
}
