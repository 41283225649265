import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';
import { PlatformID } from '@common/clients/api/types.gen';
import { useContextData } from '@common/useContextData';

import { Alignment, Columns } from './types';

import styles from './StatsTable.module.scss';

interface Props {
    columns: Columns;
}

export const TableHead: FC<Props> = ({ columns }) => {
    const { platform } = useContextData();
    let colSpanCounter = 0;

    const headers = Object.entries(columns).map(([key, column]) => {
        if (colSpanCounter) {
            colSpanCounter--;
            return <></>;
        } else if (column.headerColSpan) {
            colSpanCounter = column.headerColSpan - 1;
        }

        const classes = [];
        if (column.class) classes.push(column.class);
        if (column.hideOnMobile) classes.push(styles['hide-on-mobile']);

        // Add class if the title is used
        if (column.title) {
            classes.push(styles.Title);
        }

        switch (column.alignment) {
            case Alignment.Left:
                classes.push(styles.left);
                break;
            case Alignment.Right:
                classes.push(styles.right);
                break;
            case Alignment.Centered:
                classes.push(styles.centered);
                break;
        }

        const ChosenIcon = column.icon ? Icon[column.icon] : undefined;
        return (
            <th
                title={column.description}
                key={key}
                className={classes.join(' ')}
                colSpan={column.headerColSpan}
            >
                {platform.id === PlatformID.VI && column.abbreviatedTitle ? (
                    column.abbreviatedTitle
                ) : ChosenIcon ? (
                    <ChosenIcon />
                ) : null}
                {column.title && column.title}
            </th>
        );
    });

    return (
        <thead>
            <tr>{headers}</tr>
        </thead>
    );
};
